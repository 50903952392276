/* html,body{
  background-color: #000;
}

#root{
  background-color: #000;
} */

.square {
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.min-height-200{
  height: 200px;
  overflow-y: scroll;
}

.min-height-300{
  height: 300px;
  overflow-y: scroll;
}

.cursor-pointer{
  cursor: pointer;
}

.bg-gray{
  background-color: #f2f2f2;
}

.inputfile{
  opacity: 0;
  width: 100%;
  height: 30px;
  background-color: #333;
  z-index: 100;
}
.dropdown-toggle{
  color: #ffffff !important;
}
.bg-black{
  background-color: #000 !important;
}

a {
    color: #28a745 !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #28a745 !important;
    color: #fff !important;
}

.btn-primary {
    color: #fff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.auth input.form-control {
  background: inherit !important;
  background-color:transparent !important;
}

.auth .input-group-text {
  background: inherit !important;
  background-color:transparent !important;
}
.bg-parte-equipo{
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.big-title h1{
  font-weight: bold;
  font-size: 4em;
  color: #fff;
  line-height: 0.7em;
}

.big-title h2{
  font-weight: bold;
  font-size: 3em;
  color: #fff;
}

.big-title2 h1{
  font-weight: bold;
  font-size: 3em;
  color: #fff;
}

.big-title2 h2{
  font-weight: bold;
  font-size: 3em;
  color: #fff;
}

footer{
  padding: 50px;
  background-color: #000;
  color: #fff;
}
.text-white-toggle span{
  color: #ffffff;
}

@media (max-width: 480px) {
  .big-title h1{
    font-weight: bold;
    font-size: 3.0em;
    color: #fff;
    line-height: 0.9em;
  }
  .big-title h2{
    font-weight: bold;
    font-size: 2.0em;
    color: #fff;
  }
  .big-title2 h1{
    font-weight: bold;
    font-size: 2em;
    color: #fff;
    line-height: 0.9em;
  }
  .big-title2 h2{
    font-weight: bold;
    font-size: 2em;
    color: #fff;
    line-height: 0.9em;
  }
}
#exportar_esto .table td,
#exportar_esto .table th {
  padding: 0 !important;
  border-top: none !important;
  font-size: 12px !important;
}

.rpe table{
  margin-bottom: 1px !important;
}

.tablero{
  overflow: hidden;
  overflow-y: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.over-flow-y{
  height: 500px;
  overflow-y: scroll;
}
